.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999999;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  width: 680px;
  max-height: 100vh;
  min-height: 545px;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    display: none;
  }

  &-close-icon {
    margin-top: 15px;
    margin-right: 30px;
    float: right
  }

  &__content {
    padding: 0px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999998;

  @media screen and (max-width: 700px) {
    display: none;
  }
}