// General styling for html elements
html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: scroll;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif !important;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  // scrollbar-width: none;
  font-family: 'Open Sans', sans-serif !important;
}

*:focus {
  outline: none;
}

input {
  font-size: 14px !important;
}

p,
div {
  color: $gray-6;
}

p {
  @include sm {
    font-size: $fontsize-medium;
    line-height: $fontsize-xxlarge;
  }

  @include md {
    font-size: $fontsize-medium;
    line-height: $fontsize-xxlarge;
  }
}

h1,
h2 {
  font-weight: 400;

  @include sm {
    font-size: $fontsize-xlarge;
    line-height: $fontsize-xxlarge;
  }

  @include md {
    font-size: $fontsize-xxlarge;
    line-height: $fontsize-xxxlarge;
  }
}

h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

h5 {
  font-size: $gutter-2;
}

h1 {
  margin: $gutter-2 0;
  font-weight: 700;
  font-size: 22px;
}

a,
.link {
  text-decoration: underline !important;
  color: $color-3 !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #3dbf69 !important;
  }
}

.edit-input {
  div:not(.ant-input-number) {
    width: 60px;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  &:hover {
    div:not(.ant-input-number) {
      border-color: #3dbf69 !important;
    }
  }
}

.search-creators__request-placeholder {
  position: sticky !important;
  bottom: 0;
  z-index: 99999;
  background: #fff !important;
  border-radius: 0 !important;

  &:hover {
    background: rgba(35, 37, 66, 0.04);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.-has-delay {
  opacity: 0;
  height: 0;
  overflow: hidden;
  animation: appear 0s linear 2s forwards;
}

.pac-target-input,
.location-search {
  font-size: 14px;

  &::placeholder {
    color: rgba(35, 37, 66, 0.25) !important;
  }
}

.blankslate-icon {
  height: 100%;

  &.-checklist {
    svg {
      width: 300px;
    }
  }

  span {
    height: 100%;
  }

  svg {
    width: 72px;

    path {
      fill: #19202b;
    }
  }
}

.blankslate-description-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.blankslate-description-text {
  color: rgba(35, 37, 66, 0.88);
}

.blankslate-description {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  font-size: inherit;
}

.ant-pagination-item a {
  text-decoration: none !important;
  color: inherit !important;
}

.no-underline {
  text-decoration: none !important;
}

.ant-pagination-item-active {
  a {
    color: #3dbf69 !important;
  }
}

.btn-icon {
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

  &:hover {
    span {
      color: #1db354 !important;
    }

    svg {
      transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

      path {
        fill: #1db354 !important;
      }
    }
  }

  &:disabled {
    svg {
      path {
        fill: rgba(35, 37, 66, 0.25) !important;
      }
    }

    span {
      color: rgba(35, 37, 66, 0.25) !important;
    }
  }
}

.send-invite-btn {
  svg {
    width: 100%;
    height: 100%;
  }
}

.filter-dropdown-btn {
  display: flex;
  align-items: center;
  transition: none !important;

  &:not(:disabled):hover {
    svg {
      path {
        stroke: #1db354 !important;
      }
    }
  }

  &:disabled {
    svg {
      path {
        stroke: rgba(35, 37, 66, 0.25) !important;
      }
    }
  }
}

.ant-dropdown-open {
  .down-icon {
    transform: rotate(-180deg);
  }
}

.search-bar__networks.ant-select-open,
.add-terms__networks2.ant-select-open {
  .ant-select-arrow {
    transform: rotate(-180deg);
  }
}

.down-icon {
  display: flex !important;
}

.filter-dropdown-btn-pay {
  display: flex;
  align-items: center;
  transition: none !important;

  &:not(:disabled):hover {
    svg {
      path {
        fill: #1db354 !important;
      }
    }
  }

  &:disabled {
    svg {
      path {
        fill: rgba(35, 37, 66, 0.25) !important;
      }
    }
  }
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.modal-footer {
  margin-bottom: -16px;
  position: relative;
  margin-top: 8px;

  &:before {
    content: '';
    width: calc(100% + 48px);
    height: 1px;
    background-color: rgba(5, 5, 5, 0.06);
    position: absolute;
    top: -8px;
    left: -24px;
  }
}

.dot {
  width: 5px;
  height: 5px;
  display: block;
  background: $gray-4;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

.is-bold {
  font-weight: 700;
}

.break-spaces {
  white-space: break-spaces;
}

.filter-menu__checks {
  display: flex;
}

.container {
  max-width: $body-max-width;
  margin-left: auto;
  margin-right: auto;
  min-height: 82vh;
}

.container-error {
  background-image: url('../../images/decoration-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container-error {
  background-image: url('../../images/decoration-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  overflow: hidden;
  position: relative;

  &.-has-banner,
  &.-has-published-banner {

    .navbar-logged-desktop,
    .navbar--logged {
      top: 48px;

      &__mobile {
        top: auto;
      }
    }
  }

  &.-has-banner {
    @include sm {
      margin-top: 48px;

      .my-collaborations__title {
        margin-top: 10px !important;
      }

      .public-profile__container {
        .profile-drawer__sidemenu {
          padding-top: 40px;
        }
      }

      .account-settings {
        .fixed-body {
          margin-top: 80px !important;
        }
      }

      .profile-header {
        padding-top: 65px;
      }
    }
  }

  &.-has-published-banner {
    @include sm {
      margin-top: 60px;
    }

    &__desktop {
      @include sm-md {
        padding-top: 112px;
      }
    }
  }

  @include m-lg {
    padding-top: $gutter-8;

    &.-has-banner,
    &.-has-published-banner {
      padding-top: 112px;
    }

    &.-opportunity-xltablet {
      @include sm-md {
        padding-top: 0 !important;
      }
    }
  }

  @include sm-md {
    padding-top: $gutter-8;
  }

  &.-no-padding {
    padding-top: 0 !important;
  }
}

#root {
  padding-left: 100px !important;

  &.no-padding-all {
    padding: 0 !important;
  }

  @include sm {
    padding-left: 0 !important;
  }
}

.no-underline {
  text-decoration: none;
}

tr {
  &.-is-selected {
    background: #d9f2e6 !important;

    td {
      background: #d9f2e6 !important;
    }

    .-green-bg-btn {
      &:hover {
        background-color: #f1f3f4 !important;
        color: #19202b !important;
      }
    }

    button.ant-btn {
      &:not(.-green-btn) {
        &:hover {
          background-color: #f1f3f4 !important;
          color: #19202b !important;
        }
      }
    }
  }
}

.back-btn {
  padding: 0 4px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
}

#root.-no-vertical-menu {
  padding-left: 0 !important;
}

.profile-container {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  &.account-stats {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}

.verification-modal {
  margin-bottom: $gutter-4;
}

.pac-container {
  font-family: 'Open Sans', sans-serif !important;
  z-index: 999999;
}

.bg-white {
  background: $gray-1;
}

.picture {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.mobile-hide {
  @include sm {
    display: none;
  }
}

.card-opportunity--list-items-link {
  text-decoration: none !important;

  &:hover {
    .opportunity-card__content-picture-image {
      transform: scale(1.1);
    }

    text-decoration: none !important;
  }
}

.hover-btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 43px;
  height: 43px;
  min-width: 43px;

  &:hover {
    background: #e8ebec;
  }
}

div.PhoneInput {
  &:hover {
    border-color: #00a742 !important;
  }
}

.PhoneInput--focus.PhoneInput {
  &:hover {
    border-color: #00a742 !important;
  }
}

.pipelines-tooltip {
  *::-webkit-scrollbar {
    display: block !important;
  }

  *::-webkit-scrollbar {
    background-color: $gray-1;
    width: 16px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 16px;
    border: 5px solid #c1c1c1;
  }
}

.banner-alert {
  font-size: 16px !important;
  color: #faad14;
}

// Fix for Chrome autofill (user, password autocomplete)
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: none;
  transition: background-color 5000s ease-in-out 0s;
}

.page-break-inside-avoid {
  page-break-inside: avoid !important;
}

.empty-component {
  .ant-empty-image {
    height: 40px !important;
  }

  svg {
    height: 40px !important;
  }
}

.pipelines-side-link {
  text-decoration: none !important;
}

.my-opportunities__table,
.collab__table,
.list__table,
.payments__table,
.reporting__table,
.billing__table {
  user-select: none;
}

.-green-bg-btn {
  &:hover {
    background-color: #D9F2E6 !important;
  }
}
.-red-bg-btn {
  &:hover {
    background-color: #fbe5e5 !important;
  }
}