@import 'styles/settings/variables.scss';
.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.mobile-hidden {
  @include sm {
    display: none;
  }
}

.fontsize-xxlarge {
  font-size: $fontsize-xxlarge;
}

.fontsize-large {
  font-size: $fontsize-large;
}

.fontsize-22-px {
  font-size: 22px;
}

.fontweight-700 {
  font-weight: 700;
}

.pointer-events-none {
  pointer-events: none;
}

#cookiebanner {
  border-radius: 12px;
  opacity: 1 !important;
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  left: 0 !important;
  right: 0;
  bottom: 20px !important;

  .c-header {
    font-weight: bold !important;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px !important;
    text-transform: none !important;
    color: $gray-6 !important;
    display: flex !important;
    align-items: center !important;

    &::before {
      content: url('../../images/illustrations/cookie.svg');
      margin-right: 8px;
      position: relative;
      top: 3px;
    }
  }

  .c-message {
    font-size: 14px !important;
    line-height: 20px;
    color: $gray-6 !important;
  }

  #c-left {
    max-width: none;
  }

  #c-right {
    clear: both !important;
    float: left !important;

    .c-button {
      background: #00a742 !important;
      border-radius: 8px !important;
      box-shadow: none !important;
      border: none !important;
      font-weight: bold !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: $gray-1 !important;
      margin-bottom: 0 !important;
    }
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.z-index-1 {
  z-index: 1;
}

.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline {
  display: inline !important;
}

.text-black {
  color: $gray-6 !important;
}

.text-white {
  color: #fff !important;

  &:hover {
    color: #fff !important;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.d-block {
  display: block !important;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-gap-1 {
  gap: $gutter;
}

.gap-row-1 {
  row-gap: $gutter;
}

.gap-row-2 {
  row-gap: $gutter-2;
}

.gap-row-4 {
  row-gap: $gutter-4;
}

.gap-col-1 {
  column-gap: $gutter;
}

.gap-col-2 {
  column-gap: $gutter-2;
}

.gap-col-3 {
  column-gap: $gutter-3;
}

.dropdown-menu-item {
  padding: 5px 12px;
  cursor: pointer;
  height: 48px;

  &:hover {
    background: $gray-3;
  }
}

.text-center {
  text-align: center;
}

.text-danger {
  color: $red-3;
}

.text-error {
  color: #ff4d4f;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-gray {
  color: $gray-5;
}

.text-gray2 {
  color: rgba(35, 37, 66, 0.45);
}

.d-none {
  display: none !important;
}

.select-text-center {
  text-align: -webkit-center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 47% !important;
  padding-right: 47% !important;

  &.-creator {
    padding-left: 5px !important;
    padding-right: 35px !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.no-wrap {
  white-space: nowrap !important;
}
.text-wrap {
  white-space: wrap !important;
}

.overflow-scroll {
  overflow: scroll;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mb-5 {
  margin-bottom: 32px !important;
}

.mb-6 {
  margin-bottom: 40px !important;
}

.mb-7 {
  margin-bottom: 48px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-86 {
  margin-bottom: 86px !important;
}

.mt-86 {
  margin-top: 86px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-3 {
  padding: 16px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 16px;
}

.pb-4 {
  padding-bottom: 24px;
}

.pb-7 {
  padding-bottom: 48px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mt-5 {
  margin-top: 32px !important;
}

.mt-6 {
  margin-top: 40px;
}

.mt-7 {
  margin-top: 48px !important;
}

.mt-8 {
  margin-top: 56px;
}

.mt-10 {
  margin-top: 72px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 16px !important;
}

.ml-4 {
  margin-left: 24px !important;
}

.ml-5 {
  margin-left: 30px !important;
}

.ml-6 {
  margin-left: 40px !important;
}

.ml-7 {
  margin-left: 45px !important;
}

.ml-8 {
  margin-left: 60px !important;
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 16px !important;
}

.mr-4 {
  margin-right: 24px;
}

.mr-5 {
  margin-right: 32px !important;
}

.mr-6 {
  margin-right: 40px !important;
}

.mr-7 {
  margin-right: 48px !important;
}

.mr-8 {
  margin-right: 56px !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-3 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.border-0 {
  border: none !important;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-25 {
  width: 25%;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-30 {
  width: 30%;
}
.w-10 {
  width: 10%;
}

.w-auto {
  width: auto !important;
}

.w-max-content {
  width: max-content;
}

.h-auto {
  height: auto !important;
}

.h-fit-content {
  height: fit-content;
}

.h-30 {
  height: 30% !important;
}

.h-50 {
  height: 50% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55%;
}

.w-30 {
  width: 30%;
}

.w-66 {
  width: 66%;
}

.w-300 {
  width: 300px;
}

.w-500 {
  width: 500px;
}

.h-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw;
}

.fs-small {
  font-size: $fontsize-small !important;
}

.fs-xsmall {
  font-size: 12px !important;
}

.min-h-100vh {
  min-height: 100vh;
}

.h-75vh {
  height: 75vh;
}

.fs-xs {
  font-size: $fontsize-xsmall !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.text-underline {
  text-decoration: underline;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.align-items-inherit {
  align-items: inherit;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.input-addon-icon {
  position: absolute !important;
  bottom: 12px !important;
  font-size: 16px !important;
  left: $gutter-2 !important;
  color: $gray-6 !important;
}

.input-addon:focus ~ .input-addon-icon {
  color: $color-3 !important;
}

.input-addon {
  padding-left: $gutter-4 !important;
}

// Margin and padding forced

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 4px !important;
}

.p-3 {
  padding: 16px !important;
}

.p-4 {
  padding: 24px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 16px;
}

.pt-4 {
  padding-top: 24px;
}

.pt-5 {
  padding-top: 32px !important;
}

.pt-6 {
  padding-top: 48px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-6 {
  padding-left: 48px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 16px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-4 {
  padding-left: 24px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 16px !important;
}

.pr-4 {
  padding-right: 24px !important;
}
.pr-100 {
  padding-right: 100px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-4 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 12px !important;
}

.m-3 {
  margin: 16px !important;
}

.m-4 {
  margin: 24px !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.scale-8 {
  scale: 0.8;
}

//Ubiquous navigation patterns

@keyframes profileheader-in {
  0% {
    left: 100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes profileheader-out {
  0% {
    left: 0;
  }
  100% {
    left: 100vw;
  }
}

.navigation {
  &-level2 {
    @include sm-md {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      padding: 0;
      z-index: 500;
      @include shadow;

      &.-has-link {
        height: calc(100vh - 32px);
      }

      &.hidden {
        display: flex;
        left: 100vw;
        opacity: 0;
        transition: left 0.4s ease-in, opacity 0.1s 0.4s linear;

        .profile--header {
          animation: profileheader-out 0.4s ease-in;
        }
      }

      &.show {
        left: 0vw;
        opacity: 1;
        transition: left 0.4s ease-out, opacity 0s linear;
        z-index: 1000;

        .profile--header {
          animation: profileheader-in 0.4s ease-out;
        }
      }
    }

    &--arrow {
      position: absolute;
      top: calc(#{$gutter} * 4.5);
      left: $gutter-2;
      height: $gutter-4;
      width: $gutter-4;
      color: $gray-1;
      background: transparent;
      border: none;
      z-index: 600;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: $gutter-4;
        height: $gutter-4;
        transform: rotate(180deg);
      }

      &__profile {
        border-radius: 50%;
        transform: none;
        background-color: #{$gray-6}88;
      }

      @include md {
        top: $gutter-5;
      }

      @include lg {
        display: none;
      }
    }

    // Fix for profile header
    @include lg {
      .profile-header {
        margin-top: 0;
      }
    }
  }
}

.header-main__name {
  text-transform: none;
}

.color-white {
  color: $gray-1;
}

.color-gray-5 {
  color: $gray-5;
}

.background-white {
  background-color: $gray-1;
}

.fixed {
  @include sm {
    &-bar {
      position: fixed;
      width: 100%;
      background-color: $gray-1;
      z-index: 99;
      top: 0px;

      &-has-banner {
        position: fixed;
        width: 100%;
        background-color: $gray-1;
        z-index: 99;
        top: 65px;
      }
    }

    &-secondary-bar {
      position: fixed;
      width: 100%;
      background-color: $gray-1;
      z-index: 99;
      top: 56px;

      &-has-banner {
        position: fixed;
        width: 100%;
        background-color: $gray-1;
        z-index: 99;
        top: 0px;
        top: 116px;
      }
    }

    &-tertiary-bar {
      position: fixed;
      width: 100%;
      background-color: $gray-1;
      z-index: 99;
      top: 116px;

      &-has-banner {
        position: fixed;
        width: 100%;
        background-color: $gray-1;
        z-index: 99;
        top: 0px;
        top: 176px;
      }
    }

    &-body {
      margin-top: 64px !important;
    }

    &-body-secondary {
      margin-top: 115px !important;
    }

    &-body-tertiary {
      margin-top: 55px !important;

      &.-has-actions {
        margin-top: 120px !important;
      }
    }
  }
}

.border-bottom {
  @include sm {
    border-bottom: 1px solid $gray-3;
  }
}

.border-bottom {
  @include sm {
    border-bottom: 1px solid $gray-3;
  }
}

.capitalize {
  text-transform: capitalize;
}

.gray-5 {
  color: $gray-5;
}

.bg-warning {
  background-color: $orange-1;
}

.warning-banner {
  width: 100%;
  padding: 16px;
  background: #fff4da;
  border: 1px solid $orange-2;
  border-radius: 8px;
  @include sm {
    margin-bottom: 10px;
  }
  &__icon {
    background-color: $orange-1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    svg {
      color: #19202b;
      position: relative;
      bottom: 1px;
    }
  }
}

.no-underline {
  text-decoration: none !important;
}

.custom-scrollbar-display {
  scrollbar-width: auto !important;
  scrollbar-color: #c1c1c1 transparent !important;
  * {
    scrollbar-width: auto !important;
    scrollbar-color: #c1c1c1 transparent !important;
  }
}

.custom-scrollbar-display-visible {
  *::-webkit-scrollbar {
    display: block !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    border-right: none;
    border-left: none;
    width: 4px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c1c1c1;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@keyframes rotatePulse {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) rotate(10deg);
    opacity: 0.7;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

.rotate-pulse {
  animation: rotatePulse 1s ease-in-out;
}
