@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.public-landing {
  padding-bottom: 32px;
  
  .landing-editor__preview {
    margin-top: 24px;
  }

  @include sm {
    padding-inline: 8px
  }
}

.heepsy-form {
  margin: 24px;
  padding: 16px;
  border-radius: 8px;

  @include sm {
    margin: 16px;
    padding: 8px;
  }
}

.ant-form-item-label>label {
  color: inherit !important;
}

.ant-input,
.ant-select-selector,
.ant-checkbox-wrapper {
  color: inherit !important;
}

.highlighted-question {

  .ant-input,
  .ant-select-selector,
  .ant-checkbox-wrapper {
    border-color: #04a946;
    box-shadow: 0 0 0 1px rgba(56, 207, 30, 0.2);
    transition: all 0.3s ease;
  }
}