.landing-editor {
  display: flex;
  gap: 1rem;
  height: calc(100vh - 63px);
  background-color: #f0f0f0;

  &__container {
    flex: 0 0 65%;
    overflow-y: auto;
    height: calc(100vh - 4rem);
    padding: 1rem;
    border-radius: 10px;
  }

  &__preview {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    img {
      width: 100%;
      max-height: 245px;
      object-fit: cover;
    }

    label {
      width: 100%;
    }

    .ant-form-item-extra {
      text-align: left;
    }
  }

  &__sidebar {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 63px);
    padding: 16px;

    &-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;

      button {
        width: 30%;
      }
    }

    .ant-collapse-header-text {
      font-size: 16px;
      font-weight: 700;
    }

    .question-editor {
      border-top: none;
    }

    .question-editor__panel {
      border: 1px solid #d9d9d9;

      .ant-collapse-header {
        padding-inline: 8px !important;
        cursor: move;
      }

      .ant-collapse-content-box {
        margin-top: 0;
        padding: 16px !important;
      }

      .ant-collapse-header-text {
        span {
          cursor: pointer;
        }
      }

      .ant-collapse-expand-icon {
        cursor: pointer;
      }
    }

    form {
      label {
        font-weight: 700;
      }
    }

    &-content {
      overflow-y: auto;

      .ant-collapse-item {
        box-shadow: none !important;
        margin-bottom: 0 !important;
      }

      .ant-upload-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .ant-upload-select {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }

      .uploaded-image {
        width: 100%;
        max-height: 90px;
        object-fit: cover;
      }
    }
  }
}

.preview-modal {
  width: 100vw !important;
  top: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;

  .ant-modal-header {
    display: none;
  }

  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0 !important;
    overflow: scroll;
  }

  &__close {
    position: fixed;
    right: 16px;
  }
}

.question-editor .ant-collapse-content-box {
  background: white;
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: 8px;
  padding: 16px !important;
}

.question-editor {
  margin-bottom: 8px;
}

.question-editor .ant-collapse-header {
  transition: all 0.3s ease;
}

.question-editor .ant-collapse-content {
  background: white !important;
  border-radius: 0 0 8px 8px !important;
  margin-top: -8px;
}

.question-editor .ant-collapse-content-box {
  padding: 16px !important;
}

.section-collapse {
  .ant-collapse-item {
    background: white;
    border-radius: 8px !important;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .ant-collapse-content {
      background: white;
      border-radius: 0 0 8px 8px;
    }

    .ant-collapse-content-box {
      padding: 16px !important;
    }
  }

  .ant-collapse-expand-icon {
    display: flex;
    align-items: center;
  }
}

.question-type-options {
  padding: 10px;
  
  .question-type-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all 0.3s;
    height: 100%;
    text-align: center;
    
    &:hover {
      border-color: #00a742;
      background-color: #f0f8ff;
    }
    
    .question-type-icon {
      font-size: 24px;
      color: #00a742;
      margin-bottom: 8px;
    }
    
    .question-type-title {
      font-weight: 500;
      font-size: 14px;
    }
    
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      
      &:hover {
        border-color: #e8e8e8;
        background-color: transparent;
      }
      
      .question-type-icon {
        color: #bfbfbf;
      }
    }
  }
}