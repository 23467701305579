@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.login-signup {
  &__btn {
    &:hover {
      background: none !important;
    }
  }
  &__container {
    width: 450px;
    padding: $gutter-5;
    border: 1px solid $gray-3;
    border-radius: $gutter;
    margin-left: auto;
    margin-right: auto;

    .form-error-message {
      bottom: -15px !important;
      color: $red-3;

      &.-is-default {
        bottom: -20px !important;
      }

      &.-is-spanish {
        bottom: -40px !important;
      }

      &.-is-static {
        position: relative;
        bottom: -15px !important;
      }
    }

    &-type {
      color: rgba(35, 37, 66, 0.88);
      font-size: 14px;
      margin-bottom: 8px;
    }

    &.-is-modal {
      margin-top: 0;
      transform: none;
      width: 100%;
      padding: 0;
      border: none;
      height: auto;
    }

    &-title {
      padding: 0px 40px 24px;
      font-weight: 700;
      text-align: center;
      font-size: 24px;
    }

    &-divider {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $gray-4;
      line-height: 0.1em;
      margin: 16px 0px 24px;

      span {
        background: $gray-1;
        padding: 0 10px;
        color: $gray-5;
        font-weight: 700;
      }
    }
  }
  &__small {
    font-weight: 400;
    font-size: $fontsize-small;
    color: $gray-6;
    cursor: default;
    &-btn {
      color: $color-3;
    }
  }

  &__link {
    margin-left: 4px;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline !important;
    color: $color-3 !important;

    &:hover {
      text-decoration: underline;
      color: #3dbf69 !important;
    }
    &-blue {
      color: $color-3;
      text-decoration-line: underline !important;

      &:hover {
        color: #3dbf69 !important;
      }
    }
  }
}

.login-modal {
  @include sm {
    top: 48px !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focused,
  .ant-input-status-success.ant-input,
  .ant-input-affix-wrapper-status-success {
    background: #f2f5f9 !important;
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    }
  }

  .ant-input-affix-wrapper-status-success,
  .ant-input-affix-wrapper-focused {
    background: #f2f5f9 !important;
    input,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    }
  }

  .ant-input:focus,
  .ant-input:active,
  .ant-input-affix-wrapper > input.ant-input:focus {
    background: #f2f5f9 !important;
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
  }

  .ant-input.ant-input-status-success {
    background: #f2f5f9 !important;
  }
}

.google_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border: 1px solid #dadce0;
  font-family: "Google Sans", arial, sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #3c4043;
  cursor: pointer;
  transition: background-color .218s, border-color .218s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  flex-grow: 1;

  &:hover {
    background-color: rgba(66, 133, 244, 0.04);
    border-color: rgb(210, 227, 252);
  }
}